const initialState = {
  banner: true,
};

const Banner = (state = initialState, action) => {
  switch (action.type) {
    case ('CURRENT_Banner_REDUX'):
      return {
        ...state,
        banner: action.value,
      };

    default:
      return state;
  }
};

export default Banner;
