import React, {Component} from 'react';
import {CSVLink} from 'react-csv';
import {Button} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

/**
 * @class AsyncCSV
 */
class AsyncCSV extends Component {
  /**
   * @constructor
   * @param {*} props
   */
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
    this.csvLinkEl = React.createRef();
  }

      downloadReport = () => {
        const data = this.props.toExport;
        this.setState({data: data}, () => {
          setTimeout(() => {
            this.csvLinkEl.current.link.click();
          });
        });
      }

      /**
       * Render the button to export data in CSV
       * @return {HTML} render csv
       */
      render() {
        const {data} = this.state;
        return <>
          <Button
            className='btn btn-round btn-info mr-1'
            id="export"
            onClick={ this.downloadReport }
          >{this.props.sentence}</Button>
          <CSVLink
            headers={ this.props.headers }
            data={ data }
            filename="report.csv"
            ref={ this.csvLinkEl }
          />
        </>;
      }
}

export default AsyncCSV;
