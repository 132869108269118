import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import PropTypes from 'prop-types';
import axios from 'axios';
import DNS from '../DNS/dns.js';
// react-bootstrap components
import {
  Collapse,
  Nav,
} from 'react-bootstrap';
import {useDispatch, useSelector} from 'react-redux';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {setCurrentBuilding} from '../../actions';

/**
 * This function handle the style and js in the Sidebar's page
 * @function Sidebar
 * @param {*} routes
 * @param {*} image
 * @param {*} background
 * @return {HTML} Sidebar's page
 */
function Sidebar({routes, image, background}) {
  const token = useSelector((state) => state.token);
  const currentBuilding = useSelector((state) => state.currentBuilding);
  const building = useSelector((state) => state.building);
  const dispatch = useDispatch();


  const changeBuilding = (element) => {
    dispatch(setCurrentBuilding(element));
    window.location.reload();
  };

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };

  // to check for active links and opened collapses
  const location = useLocation();
  // this is for the user collapse
  const [userCollapseState, setUserCollapseState] = React.useState(false);
  // this is for the rest of the collapses
  const [toggle, setToggle] = React.useState(false);
  const [toggleInfo, setToggleInfo] = React.useState({id: 0, value: false});
  const [state, setState] = React.useState({});
  React.useEffect(() => {
    getEmailState();
    setState(getCollapseStates(routes));
  }, []);
  // this creates the intial state of this component based on the collapse routes
  // that it gets through routes prop
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };

  const getEmailState = () => {
    axios
        .get(
            DNS +
          '/api/enabler/',
            requestOptionsAxiosGet,
        )
        .then((response) => {
          setToggleInfo({id: response.data[0].id, value: response.data[0].enable_email})
          console.log(response.data)
        });
  };

  const handleToggleClick = () => {

    
      const requestOptions = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'token ' + token['tokenRedux'],
        },
      };
      fetch(
          DNS +
          '/api/enabler/' + toggleInfo.id,
          requestOptions,
      ).then(async (response) => {
        // console.log(response)
        getEmailState()  
      });
  }

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (location.pathname === routes[i].layout + routes[i].path) {
        return true;
      }
    }
    return false;
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.redirect || prop['path'] == '/statsListeArrivee' || prop['path'] == '/statsBadge' || prop['path'] == '/statsReservation' || prop['path'] == '/statsColis') {
        return null;
      }
      if (prop.collapse) {
        const st = {};
        st[prop['state']] = !state[prop.state];
        return (
          <Nav.Item
            className={getCollapseInitialState(prop.views) ? 'active' : ''}
            as="li"
            key={key}
          >
            <Nav.Link
              className={state[prop.state] ? 'collapsed' : ''}
              data-toggle="collapse"
              onClick={(e) => {
                e.preventDefault();
                setState({...state, ...st});
              }}
              aria-expanded={state[prop.state]}
            >
              <i className={prop.icon}></i>
              <p>
                {prop.name} <b className="caret"></b>
              </p>
            </Nav.Link>
            <Collapse in={state[prop.state]}>
              <div>
                <Nav as="ul">{createLinks(prop.views)}</Nav>
              </div>
            </Collapse>
          </Nav.Item>
        );
      }
      return (
        <Nav.Item
          className={activeRoute(prop.layout + prop.path)}
          key={key}
          as="li"
        >
          <Nav.Link to={prop.layout + prop.path} as={Link}>
            {prop.icon ? (
                  <>
                    <i className={prop.icon} />
                    <p>{prop.name}</p>
                  </>
              ) : (
                  <>
                    <span className="sidebar-mini">{prop.mini}</span>
                    <span className="sidebar-normal">{prop.name}</span>
                  </>
              )}
          </Nav.Link>
        </Nav.Item>
      );
    });
  };
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname === routeName ? 'active' : '';
  };
  return (
    <>
      <div className="sidebar" data-color={"black"} data-image={image}>
        <div className="sidebar-wrapper">
          <div className="logo">
            <a
              className="simple-text logo-normal"
              href="http://www.haveagooday.com"
            >
              <img
                src={require('assets/img/logo-havea.png').default}
                alt="react-logo"
              />
            </a>
          </div>
          <br/>
          <FormControlLabel
          value="Email Presence"
          checked={toggleInfo?.value}
          control={<Switch onClick={() => handleToggleClick()} color="warning" />}
          label="Email Presence"
          labelPlacement="start"
        />
          <Nav as="ul">{createLinks(routes)}</Nav>
        </div>
        <div
          className="sidebar-background"
          style={{
            backgroundImage: 'url(\'' + image + '\')',
          }}
        ></div>
      </div>
    </>
  );
}

const linkPropTypes = {
  path: PropTypes.string,
  layout: PropTypes.string,
  name: PropTypes.string,
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
};

Sidebar.defaultProps = {
  image: '',
  background: 'black',
  routes: [],
};

Sidebar.propTypes = {
  image: PropTypes.string,
  background: PropTypes.oneOf([
    'black',
    'azure',
    'green',
    'orange',
    'red',
    'purple',
  ]),
  routes: PropTypes.arrayOf(
      PropTypes.oneOfType([
        PropTypes.shape({
          ...linkPropTypes,
          icon: PropTypes.string,
        }),
        PropTypes.shape({
          collapse: true,
          path: PropTypes.string,
          name: PropTypes.string,
          state: PropTypes.string,
          icon: PropTypes.string,
          views: PropTypes.shape({
            ...linkPropTypes,
            mini: PropTypes.string,
          }),
        }),
      ]),
  ),
};

export default Sidebar;
