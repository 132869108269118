const initialState = {
  tokenRedux: '',
  currentbuildingRedux: '',
  buildingRedux: '',
  user: {},
};

const User = (state = initialState, action) => {
  switch (action.type) {
    case ('CURRENT_USER'):
      return {
        ...state,
        user: action.value,
      };

    default:
      return state;
  }
};

export default User;
