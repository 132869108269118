import React from 'react';
import {useLocation, Route, Switch} from 'react-router-dom';

import AdminNavbar from 'components/Navbars/AdminNavbar';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import FixedPlugin from 'components/FixedPlugin/FixedPlugin.js';
import {routesFunction} from 'routes.js';
import {useSelector} from 'react-redux';
import CookieConsent from 'react-cookie-consent';
import {useDispatch} from 'react-redux';
import DNS from '../components/DNS/dns.js';
import axios from 'axios';

import {
  setUser,
} from '../actions/index';

import sidebarImage from 'assets/img/sidebar-3.jpg';

/**
 * This function handle the style and js in the Admin's page
 * @function Admin
 * @return {HTML} Admin's page
 */
function Admin() {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.token);
  const currentBuilding = useSelector((state) => state.currentBuilding);
  const user = useSelector((state) => state.user);

  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState('black');
  const [hasImage, setHasImage] = React.useState(true);
  const [routes, setRoutes] = React.useState([]);
  const location = useLocation();
  const mainPanel = React.useRef(null);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainPanel.current.scrollTop = 0;
    if (
      window.innerWidth < 993 &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
      const element = document.getElementById('bodyClick');
      element.parentNode.removeChild(element);
    }
    routesFunction(token['tokenRedux'], currentBuilding['currentbuildingRedux'].pk, user['user'].role).then((e) => setRoutes(e));
  }, [location]);

  const getRoutes = (routes) => {
    console.log(routes);
    return routes.map((prop, key) => {
      if (prop.layout === '/admin') {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component {...props} />}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getAccountInfo = () => {
    const requestOptionsAxiosGet = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
    };
    axios
        .get(DNS + '/users/getUserInfo/', requestOptionsAxiosGet)
        .then((response) => {
          console.log(response.data);
          dispatch(setUser(response.data));
        });
  };

  const setBannerValid = () => {
    const requestOptionsAxiosPatch = {
      pk: user['user'].pk,
      banner: true,
    };
    const requestOptionsAxiosHeaders = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
    };
    axios
        .patch(
            DNS + '/users/CRUDUser/',
            JSON.stringify(requestOptionsAxiosPatch),
            requestOptionsAxiosHeaders,
        )
        .then((response) => getAccountInfo());
  };

  return (
    <>
      <div className="wrapper">
        <Sidebar color={color} image={hasImage ? image : ''} routes={routes} />
        <div className="main-panel" ref={mainPanel}>
          <AdminNavbar />
          <div className="content">
            <Switch>{getRoutes(routes)}</Switch>
          </div>
          <Footer />
        </div>
      </div>
      <FixedPlugin
        hasImage={hasImage}
        setHasImage={() => setHasImage(!hasImage)}
        color={color}
        setColor={(color) => setColor(color)}
        image={image}
        setImage={(image) => setImage(image)}
      />
      <CookieConsent
        location='bottom'
        buttonText='Sure man!!'
        cookieName='myAwesomeCookieName2'
        style={{background: '#2B373B'}}
        buttonStyle={{color: '#4e503b', fontSize: '13px'}}
        onAccept={() => setBannerValid()}
        visible={user['user'].banner == false ? 'show' : 'hidden'}
        overlay={true}
      >
        This website uses cookies to enhance the user experience.{' '}
        <span style={{fontSize: '10px'}}>This bit of text is smaller :O</span>
      </CookieConsent>
    </>
  );
}

export default Admin;
