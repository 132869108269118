import {combineReducers} from 'redux';

import Token from './Token';
import Building from './Building';
import CurrentBuilding from './CurrentBuilding';
import User from './user';
import Banner from './Banner';

const allReducers = combineReducers({
  token: Token,
  building: Building,
  currentBuilding: CurrentBuilding,
  user: User,
  banner: Banner,
});

export default allReducers;
