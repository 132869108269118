export const setToken = (param) => {
  return {type: 'TOKEN_REDUX', value: param};
};

export const setBuilding = (param) => {
  return {type: 'BUILDING_REDUX', value: param};
};

export const setCurrentBuilding = (param) => {
  return {type: 'CURRENT_BUILDING_REDUX', value: param};
};

export const setUser = (param) => {
  return {type: 'CURRENT_USER', value: param};
};

export const setCookiesBanner = (param) => {
  return {type: 'CURRENT_Banner_REDUX', value: param};
};

// export const setRoleUser = (param) => {
//     return { type: 'CURRENT_ROLE_USER', value: param }
// }
