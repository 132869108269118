import * as React from 'react';
import frLocale from 'date-fns/locale/fr';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import TextField from '@mui/material/TextField';
import {TimePicker, StaticTimePicker} from '@mui/x-date-pickers';
import Stack from '@mui/material/Stack';
import {MobileTimePicker} from '@mui/x-date-pickers/MobileTimePicker';
import {isMobile} from 'react-device-detect';
import {styled} from '@mui/material/styles';

const TimePickerComponent = ({showHour, setHour, validated, disabled=false}) => {
  const CssTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiOutlinedInput-root.Mui-error': {
      '& fieldset': {
        borderColor: validated ? 'rgb(211,57,49)' : 'rgba(0, 0, 0, 0.23)',
      },
    },
    '& .MuiInputLabel-root.Mui-error': {
      color: validated ? 'rgb(211,57,49)' : 'rgb(108,117,125)',
    },
    '& .MuiOutlinedInput-root.Mui-error.Mui-focused': {
      '& fieldset': {
        borderColor: validated ? 'rgb(211,57,49)' : 'rgb(25,118,210)',
      },
    },
    '& label.Mui-focused.Mui-error': {
      color: validated ? 'rgb(211,57,49)' : 'rgb(25,118,210)',
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={frLocale} >
      <Stack spacing={3}>
        { !isMobile ?
          disabled == false ?
            <MobileTimePicker
              label={null}
              value={showHour}
              onChange={(e) => setHour(e)}
              input={(params) => {
                return (<CssTextField
                  id="outlined-size-small"
                  defaultValue="Small"
                  size="small"
                  style={{zIndex: 1}} {...params} />);
              }
              }
            /> :
            <MobileTimePicker
              label={null}
              disabled
              value={showHour}
              onChange={(e) => setHour(e)}
              input={(params) => {
                return (<CssTextField
                  id="outlined-size-small"
                  defaultValue="Small"
                  size="small"
                  style={{zIndex: 1}} {...params} />);
              }
              }
            /> :
          disabled == false ?
          <MobileTimePicker
            label={null}
            value={showHour}
            onChange={(e) => setHour(e)}
            DialogProps={{
              PaperProps: {
                sx: {'& *:focus': {outline: 'none'}},
              },
            }}
            input={(params) => {
              return (<CssTextField
                id="outlined-size-small"
                defaultValue="Small"
                size="small"
                style={{zIndex: 1}} {...params} />);
            }
            }
          /> :
          <MobileTimePicker
            label={null}
            disabled
            value={showHour}
            onChange={(e) => setHour(e)}
            input={(params) => {
              return (<CssTextField
                id="outlined-size-small"
                defaultValue="Small"
                size="small"
                style={{zIndex: 1}} {...params} />);
            }
            }
          />
        }
      </Stack>
    </LocalizationProvider>
  );
};

export default TimePickerComponent;
