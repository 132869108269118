import React from 'react';
import ReactDOM from 'react-dom';

import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/animate.min.css';
import './assets/scss/light-bootstrap-dashboard-react.scss?v=2.0.0';

import './assets/css/main.css';
import './assets/css/demo.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

import {createStore} from 'redux';
import {Provider} from 'react-redux';

import AdminLayout from 'layouts/Admin.js';
import Login from './views/Login';
import Confirm from './views/Confirm';
import Register from './views/Register';
import Appointment from './views/Appointment.js';

import allReducers from './reducers/index';
import AesEncryption from 'aes-encryption';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    const aes = new AesEncryption();
    aes.setSecretKey('11122233344455566677788822244455555555555555555231231321313aaaff');
    // Note: secretKey must be 64 length of only valid HEX characters, 0-9, A, B, C, D, E and F
    return JSON.parse(aes.decrypt(serializedState));
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    const aes = new AesEncryption();
    aes.setSecretKey('11122233344455566677788822244455555555555555555231231321313aaaff');
    // Note: secretKey must be 64 length of only valid HEX characters, 0-9, A, B, C, D, E and F
    localStorage.setItem('state', aes.encrypt(serializedState));
  } catch (e) {
    return undefined;
  }
};

const peristedState = loadState();

const store = createStore(allReducers, peristedState);

store.subscribe(() => {
  saveState(store.getState());
});

ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Switch>
          {store.getState().token['tokenRedux'] != '' && store.getState().user['user'].role != '' && store.getState().user['user'].role != 'collab' ?
          <Route path="/admin" render={(props) => <AdminLayout {...props} />} /> :
          <Redirect from='/admin' to='/login' />
          }
          {store.getState().token['tokenRedux'] == '' || store.getState().user['user'].role == 'collab' || store.getState().user['user'].role == 'superCollab' ?
          window.location.href == 'http://localhost:3000/register' ? <Route path="/register" render={(props) => <Register {...props} />} /> :
            window.location.href == 'http://localhost:3000/resetPassword' ? <Route path="/resetPassword" render={(props) => <Reset {...props} />} /> :
              window.location.href.includes('http://localhost:3000/confirmReset') == true ? <Route path="/confirmReset" render={(props) => <Confirm {...props} />} /> :
                <Route path="/login" render={(props) => <Login {...props} />} /> : <Redirect from='/login' to='/admin/table' />
          }
          <Route path="/appointment" render={() => <Appointment />} />
          <Route path="*">
            <Redirect to='login' />
          </Route>
        </Switch>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
);
