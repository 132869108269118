import React from 'react';
import {useState} from 'react';
import DNS from '../components/DNS/dns.js';
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Navbar,
  Container,
  Col,
} from 'react-bootstrap';
import {Row} from 'react-foundation';

import 'assets/css/register.css';
import NotificationAlert from 'react-notification-alert';
import axios from 'axios';

/**
 * This function handle the style and js in the Confirm's page
 * @function Confirm
 * @return {HTML} Confirm's page
 */
const Confirm = () => {
  const [showPassword, setPassword] = useState('');
  const collapseOpen = false;
  const [logError, setLogError] = useState(false);
  const [cardClasses, setCardClasses] = React.useState('card-hidden');

  const notificationAlertRef = React.useRef(null);
  const notify = (place) => {
    const type = 'danger';
    let options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <p>Votre adresse mail est incorrect. Veuillez le vérifier.</p>
          </div>
        </div>
      ),
      type: type,
      icon: 'nc-icon nc-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    setLogError(false);
  };

  const resetPassword = () => {
    const requestOptionsAxiosHeaders = {
      headers: {'Content-Type': 'application/json'},
    };

    const url = window.location.href;
    const Token = url.split('/')[5];
    const Uid = url.split('/')[4];
    const requestOptionsAxiosPatch = {
      uid: null,
      token: null,
      password: null,
    };

    if (showPassword) {
      requestOptionsAxiosPatch['password'] = showPassword;
    }
    if (Uid) {
      requestOptionsAxiosPatch['uid'] = Uid;
    }
    if (Token) {
      requestOptionsAxiosPatch['token'] = Token;
    }
    console.log(requestOptionsAxiosPatch);
    axios
        .patch(
            DNS + '/users/resetPassword/',
            JSON.stringify(requestOptionsAxiosPatch),
            requestOptionsAxiosHeaders,
        )
        .then((response) => {
          console.log('test', response);
          window.location.replace('http://localhost:3000/login');
        });
  };

  React.useEffect(() => {
    setTimeout(function() {
      setCardClasses('');
    }, 1000);
  }, []);

  return (
    <>
      <Navbar
        className="position-absolute w-100"
        expand="lg"
        variant={collapseOpen ? 'white' : 'transparent'}
        style={{
          paddingTop: '15px',
          backgroundColor: 'initial',
          borderBottom: '1px solid transparent',
          zIndex: '999',
        }}
      >
        <Container>
          <div className="navbar-wrapper">
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              <img src={require('assets/img/WeSmile-HAGD.png').default} />
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>

      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require('assets/img/background-login.jpg').default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="6" md="8">
              <Form onSubmit={resetPassword} className="form" method="">
                {logError == true ? notify('tc') : ''}
                <Card className={'card-login ' + cardClasses}>
                  <Card.Header>
                    <h3 className="header text-center">
                      Veuillez entrez votre nouveau mot de passe
                    </h3>
                  </Card.Header>
                  <Card.Body>
                    <Card.Body>
                      <Form>
                        <Row>
                          <Col>
                            {/* EMAIL FIELD  */}
                            <Form.Group controlId="formBasicEmail">
                              <Form.Label>Mot de Passe:</Form.Label>
                              <Form.Control
                                required
                                type="password"
                                placeholder="Entrez votre nouveau mot de passe"
                                onChange={(e) => {
                                  setPassword(e.currentTarget.value);
                                }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </Card.Body>
                  </Card.Body>
                  <Card.Footer className="ml-auto mr-auto">
                    <Button className={'btn-round btn-wd'} type="submit">
                      Envoyer
                    </Button>
                    <a href="/login">
                      <Button className="btn-wd btn-round " variant="warning">
                        Retour
                      </Button>
                    </a>
                    <div className="clearfix"></div>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              'url(' + require('assets/img/background-login.jpg').default + ')',
          }}
        ></div>
        <footer
          className="footer position-absolute fixed-bottom"
          style={{
            position: 'relative',
            zIndex: '2',
            background: 'transparent',
          }}
        >
          <div className="container">
            <nav>
              <p className="text-center m-0" style={{color: 'white'}}>
                © {new Date().getFullYear()}{' '}
                Property of <a href="http://www.haveagooday.com/">Haveagooday</a>, made by Imperiatec.
              </p>
            </nav>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Confirm;
