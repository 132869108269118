import React, {Component} from 'react';
import {Container} from 'react-bootstrap';

/**
 * @class Footer
 * @return {HTML} return the footer
 */
class Footer extends Component {
  /**
   * This is the render function
   * @return {HTML} render
   */
  render() {
    return (
      <footer className="footer px-0 px-lg-3">
        <Container fluid>
          <nav>
            <p className="copyright text-center">
              © {new Date().getFullYear()}{' '}
              Property of <a href="http://www.haveagooday.com/">Haveagooday</a>, made by WelcomeData.
            </p>
          </nav>
        </Container>
      </footer>
    );
  }
}

export default Footer;
