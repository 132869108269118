import React, {useState, useEffect} from 'react';
import DNS from '../components/DNS/dns.js';
import '../assets/css/appointment.css'; // main css file
import axios from 'axios';
// react-bootstrap components
import {
  Container,
  Row,
  Col,
} from 'react-bootstrap';

import AppointmentForm from '../components/Appointment/AppointmentForm.js';

/**
 * This function handle the style and js in the Appointment's page
 * @function Appointment
 * @return {HTML} Appointment's page
 */
const Appointment = () => {
  const [external, setExternal] = useState(false);
  const [building, setBuilding] = useState('');
  const [buildingList, setBuildingList] = useState([]);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const getUrlParams = () => {
    const urlSearch = window.location.search;
    const urlParams = new URLSearchParams(urlSearch);
    if (urlSearch.includes('external')) {
      localStorage.setItem('hagd-external', urlParams.get('external'));
    } else {
      localStorage.setItem('hagd-external', false);
    }
    if (urlSearch.includes('building')) {
      localStorage.setItem('hagd-building', urlParams.get('building'));
    }
  };

  useEffect(() => {
    getUrlParams();
    const storedBuildingNb = localStorage.getItem('hagd-building');
    const external = localStorage.getItem('hagd-external');
    if (storedBuildingNb === null) {
      axios.get(DNS + '/appointment/', requestOptions)
          .then(function(response) {
            setBuildingList(response.data);
          })
          .catch(function(error) {
            console.log(error);
          });
    } else {
      setBuilding(storedBuildingNb);
    }
    if (external !== null) {
      setExternal(external);
    }
  }, []);

  const BuildingChoice = () => {
    const saveBuildingNb = (e) => {
      setBuilding(e.target.value);
      localStorage.setItem('hagd-building', e.target.value);
    };

    return (
      <Col className="row justify-content-md-center" style={{display: 'flex'}} md="12" >

        <select onChange={saveBuildingNb}>
          <option>Aucun bâtiment sélectionné</option>
          {buildingList.map((elem, index) => {
            return <option value={elem.name} key={elem.name}>{elem.name}</option>;
          })}
        </select>
      </Col >
    );
  };

  const AppointmentViewManager = () => {
    if (building) {
      return (
        <AppointmentForm building={building} external={external} />
      );
    } else {
      return (
        <BuildingChoice />
      );
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <AppointmentViewManager />
        </Row >
      </Container >
    </>
  );
};
export default Appointment;
