import React from 'react';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useEffect} from 'react';
import DNS from '../components/DNS/dns.js';
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import axios from 'axios';

/**
 * This function handle all the event and style in the profile page.
 * @function MyProfile
 * @return {HTML} profile's page
 */
function MyProfile() {
  const token = useSelector((state) => state.token);
  const Buildings = useSelector((state) => state.building);

  const [showRole, setRole] = useState('');
  const [showEmail, setEmail] = useState('');
  const [showFname, setFname] = useState('');
  const [showLname, setLname] = useState('');
  const [showOrganization, setOrganization] = useState([]);
  const [showPassword, setPassword] = useState('');
  const [validated, setValidated] = useState(false);
  const [showApPk, setApPk] = useState('');

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };

  useEffect(() => {
    getAccountInfo();
  }, []);

  const getAccountInfo = () => {
    axios
        .get(DNS + '/users/getUserInfo/', requestOptionsAxiosGet)
        .then((response) => {
          console.log('test', response);
          setEmail(response.data.email);
          setFname(response.data.firstName);
          setLname(response.data.lastName);
          setOrganization(response.data.nameEntreprise);
          setRole(response.data.role);
          setApPk(response.data.pk);
          setPassword('');
        });
  };

  const requestOptionsAxiosHeaders = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };

  const editAcccount = () => {
    const requestOptionsAxiosPatch = {
      pk: showApPk,
      email: showEmail,
      firstName: showFname,
      lastName: showLname,
      organization: showOrganization,
      active: true,
      is_staff: true,
      confirm: true,
    };

    if (showPassword) {
      requestOptionsAxiosPatch['password'] = showPassword;
    }
    console.log(requestOptionsAxiosPatch);
    axios
        .patch(
            DNS + '/users/CRUDUser/',
            JSON.stringify(requestOptionsAxiosPatch),
            requestOptionsAxiosHeaders,
        )
        .then((response) => console.log('test', response));
    window.localStorage.clear();
  };

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false || showEmail == '' || showLname == '' || showFname == '' || showPassword == '') {
      event.preventDefault();
      event.stopPropagation();
    } else {
      editAcccount();
    }
  };

  return (
    <>
      <Container fluid>
        <Row>
          <Col style={{marginLeft: 'auto', marginRight: 'auto'}} md="8">
            <Card>
              <Card.Header>
                <Card.Title as="h4">Modifier mon profil:</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Form>
                    <Row>
                      <Col>
                        {/* EMAIL FIELD  */}
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Adresse Email:</Form.Label>
                          <Form.Control
                            required
                            type="email"
                            placeholder="Entrez un email"
                            value={showEmail}
                            onChange={(e) => {
                              setEmail(e.currentTarget.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Entrez un email valide
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  <Form>
                    <Row>
                      <Col>
                        {/* LASTNAME FIELD  */}
                        <Form.Group controlId="formBasicLastname">
                          <Form.Label>Nom:</Form.Label>
                          <Form.Control
                            required
                            type="Lastname"
                            placeholder="Entrez un nom"
                            value={showLname}
                            onChange={(e) => {
                              setLname(e.currentTarget.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Entrez un nom valide
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        {/* FIRSTNAME FIELD  */}
                        <Form.Group controlId="formBasicFirstname">
                          <Form.Label>Prénom:</Form.Label>
                          <Form.Control
                            required
                            type="firstname"
                            placeholder="Entrez un prénom"
                            value={showFname}
                            onChange={(e) => {
                              setFname(e.currentTarget.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Entrez un prénom valide
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  <Form>
                    <Row>
                      <Col>
                        {/* PASSWORD FIELD  */}
                        <Form.Group controlId="formBasicPassword">
                          <Form.Label>Mot de passe:</Form.Label>
                          <Form.Control
                            required
                            type="password"
                            placeholder="Entrez un mot de passe"
                            value={showPassword}
                            onChange={(e) => {
                              setPassword(e.currentTarget.value);
                            }}
                          />
                          <Form.Control.Feedback type="invalid">
                            Entrez un mot de passe valide
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Col>
                      <Col>
                        {/* ORGANIZATION  */}
                        <Form.Group controlId="formBasicOrganization">
                          <Form.Label>Nom de l'entreprise:</Form.Label>
                          <Form.Control
                            required
                            disabled
                            type="Company"
                            placeholder="Entrez un nom d'entreprise"
                            value={showOrganization}
                            onChange={(e) => {
                              setOrganization(e.currentTarget.value);
                            }}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  <Form>
                    <Row>
                      <Col>
                        {/* ROLE  */}
                        <Form.Group controlId="formBasicOrganization">
                          <Form.Label>Rôle:</Form.Label>
                          <Form.Control disabled required value={showRole} />
                        </Form.Group>
                      </Col>
                      <Col>
                        {/* BUILDING  */}
                        <Form.Group controlId="formBasicOrganization">
                          <Form.Label>Site:</Form.Label>
                          <Form.Control
                            disabled
                            required
                            value={Buildings['buildingRedux'][0].name}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Form>

                  <Button className={'btn-round btn-wd'} type="submit">
                    Modifier votre profil
                  </Button>
                  <div className="clearfix"></div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyProfile;
