import TableList from 'views/TableList.js';
import HourList from 'views/Hours.js';
import MyProfile from 'views/myProfile.js';
import Building from './views/Building.js';
import UserList from './views/UserList.js';
import Park from './views/Park.js';
import {createStore} from 'redux';
import allReducers from './reducers/index';
import {areaRequest} from 'components/urlsEvent/areaUrls.js';
import MyHistoryList from 'views/History.js';

const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (e) {
    return undefined;
  }
};

const peristedState = loadState();

const store = createStore(allReducers, peristedState);

store.subscribe(() => {
  saveState(store.getState());
});

let dashboardRoutes = [
  {
    path: '/table',
    name: 'Liste des Présences',
    icon: 'nc-icon nc-notes',
    component: TableList,
    layout: '/admin',
  },
  {
    path: '/hours',
    name: 'Horaires',
    icon: 'nc-icon nc-watch-time',
    component: HourList,
    layout: '/admin',
  },
  {
    path: '/building',
    name: 'Batiment',
    icon: 'nc-icon nc-square-pin',
    component: Building,
    layout: '/admin',
  }, {
    path: '/park',
    name: 'Parc de Batiment',
    icon: 'nc-icon nc-circle-09',
    component: Park,
    layout: '/admin'
  },
  {
    path: '/history',
    name: 'Mon Historique',
    icon: 'nc-icon nc-circle-09',
    component: MyHistoryList,
    layout: '/admin',
  },
  // {
  //   path: '/AccountList',
  //   name: 'Liste des comptes',
  //   icon: 'nc-icon nc-circle-09',
  //   component: UserList,
  //   layout: '/admin',
  // },
];

/**
 * This function wait the Area to be determined
 * @function routesFunction
 * @param {*} tokenUser
 * @param {*} pkBuilding
 * @param {*} role
 * @return {Array} Area
 */
export async function routesFunction(tokenUser, pkBuilding, role) {
  return dashboardRoutes;
}

export default dashboardRoutes;
