import React from 'react';
import {useState} from 'react';
// react-bootstrap components
import {
  Button,
  Card,
  Form,
  Navbar,
  Container,
  Col,
} from 'react-bootstrap';

import 'assets/css/register.css';
import NotificationAlert from 'react-notification-alert';

/**
 * This function handle the style and js in the Register's page
 * @function Register
 * @return {HTML} Register's page
 */
const Register = () => {
  const [logError, setLogError] = useState(false);

  const notificationAlertRef = React.useRef(null);
  const notify = (place) => {
    const type = 'danger';
    let options = {};
    options = {
      place: place,
      message: (
        <div>
          <div>
            <p>
              Votre adresse mail ou mot de passe est incorrect. Veuillez le
              vérifier.
            </p>
          </div>
        </div>
      ),
      type: type,
      icon: 'nc-icon nc-bell-55',
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
    setLogError(false);
  };

  const collapseOpen = false;

  return (
    <>
      <Navbar
        className="position-absolute w-100"
        expand="lg"
        variant={collapseOpen ? 'white' : 'transparent'}
        style={{
          paddingTop: '15px',
          backgroundColor: 'initial',
          borderBottom: '1px solid transparent',
          zIndex: '999',
        }}
      >
        <Container>
          <div className="navbar-wrapper">
            <Navbar.Brand href="#pablo" onClick={(e) => e.preventDefault()}>
              <img src={require('assets/img/logo-havea.png').default} />
            </Navbar.Brand>
          </div>
        </Container>
      </Navbar>

      <div className="rna-container">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div
        className="full-page section-image"
        data-color="black"
        data-image={require('assets/img/background-login.jpg').default}
      >
        <div className="content d-flex align-items-center p-0">
          <Container>
            <Col className="mx-auto" lg="6" md="8">
              <Form className="form" method="">
                {logError == true ? notify('tc') : ''}
                <Card className={'card-login '}>
                  <Card.Header>
                    <h1 className="header text-center">
                      La page d'inscription se trouve sur la platerforme Résident.
                    </h1>
                  </Card.Header>
                  <Card.Footer className="ml-auto mr-auto">
                    <br />
                    <a href="/login">
                      <Button className="btn-wd btn-round " variant="warning">
                        Retour
                      </Button>
                    </a>
                    <div className="clearfix"></div>
                  </Card.Footer>
                </Card>
              </Form>
            </Col>
          </Container>
        </div>
        <div
          className="full-page-background"
          style={{
            backgroundImage:
              'url(' + require('assets/img/background-login.jpg').default + ')',
          }}
        ></div>

        <footer
          className="footer position-absolute fixed-bottom"
          style={{
            position: 'relative',
            zIndex: '2',
            background: 'transparent',
          }}
        >
          <div className="container">
            <nav>
              <p className="text-center m-0" style={{color: 'white'}}>
                © {new Date().getFullYear()}{' '}
                Property of <a href="http://www.haveagooday.com/">Haveagooday</a>, made by Imperiatec.
              </p>
            </nav>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Register;
