import 'assets/css/HAGD-Custom.css';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import axios from 'axios';
import AsyncCSV from 'components/csvExport';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {fr} from 'react-date-range/src/locale/index';
import DNS from '../components/DNS/dns.js';
import Moment from 'react-moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import TimePickerComponent from 'components/DateTime/TimePicker';
import DatePickerComponent from 'components/DateTime/DatePicker';

// react-bootstrap components
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
} from 'react-bootstrap';
import {DateRange} from 'react-date-range';

const Park = () => {
  const token = useSelector((state) => state.token);
  // const currentBuilding = useSelector((state) => state.currentBuilding);
  const user = useSelector((state) => state.user);
  const animatedComponents = makeAnimated();

  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showParkName, setParkName] = useState('');
  const [showParkEmail, setParkEmail] = useState('');
  const [showHour, setHour] = useState('');
  const [showDate, setDate] = useState('');
  const [showApPk, setApPk] = useState('');
  const [showEmail, setEmail] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [buildingList, setBuildingList] = useState('');
  const [sortState, setSortState] = useState('neutral')
  const [ogList, setOgList] = useState([])

  const [showBatimentSelected, setBatimentSelected] = useState([]);

  const [showTodayDate, setTodayDate] = useState('');
  const [showMaxdayDate, setMaxdayDate] = useState('');
  const [validated, setValidated] = useState(false);

  const [alist, setList] = useState([]);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };
  useEffect(() => {
    // getBatiment();
    getTodayDate();
    getBuildingList();
    getParkList()
  }, []);


  useEffect(() => {
    let tmp = ogList
    console.log(ogList)

    if (sortState == 'neutral') {
      setList(ogList)
    }


    if (sortState == 'Reversename') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let nameA = a?.name?.toLowerCase();
        let nameB = b?.name?.toLowerCase();

        return nameA?.localeCompare(nameB);
    });
      setList(toSort)
    }

    if (sortState == 'name') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let nameA = a?.name?.toLowerCase();
        let nameB = b?.name?.toLowerCase();

        return nameB?.localeCompare(nameA);
    });
      setList(toSort)
    }

    if (sortState == 'Reverseemail') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let emailA = a?.email?.toLowerCase();
        let emailB = b?.email?.toLowerCase();

        return emailA?.localeCompare(emailB);
      })
      setList(toSort)
    }
    if (sortState == 'email') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let emailA = a?.email.toLowerCase();
        let emailB = b?.email.toLowerCase();

        return emailB.localeCompare(emailA);
        })
      setList(toSort)
    }

  }, [sortState])

  const handleSortState = (sortingOrder) => {

    if (sortingOrder == 'email' ) {
      if (sortState == 'email') {
        setSortState('Reverseemail')
      } else {
        setSortState('email')
      }
    }

    if (sortingOrder == 'name' ) {
      if (sortState == 'name') {
        setSortState('Reversename')
      } else {
        setSortState('name')
      }
    }


  }

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };

  const formatTimeRange = (timeRange) => {
    const [startTimeStr] = timeRange?.split(' - ');
    const [hours, minutes, seconds] = startTimeStr?.split(':');
    return new Date(0, 0, 0, hours, minutes, seconds);
  }


  const resetValues = () => {
    setValidated(false);
    setParkName('');
    setParkEmail('')
    setEmail('')
    setApPk('');
    setBatimentSelected('')
  };


  const getBuildingList = () => {
    axios.get(
        DNS +
        '/api/building/' ,
        requestOptionsAxiosGet,
    ).then(async (response) => {
          let tmp = [];
          response.data.map((elem) => {
            tmp = tmp.concat({'value': elem.id, 'label': elem.name});
          });
          setBuildingList(tmp);
    });
  };



  const getParkList = () => {
    axios.get(
        DNS +
        '/api/park/' ,
        requestOptionsAxiosGet,
    ).then(async (response) => {
          // console.log(response);
          let tmp = [];
          response.data.map((elem) => {
            tmp = tmp.concat(elem);
          });
          setList(tmp);
          setOgList(tmp) 
          console.log("parks:   ", tmp)
    });
  };

  const deletePark = (personpk) => {
    // console.log(token['tokenRedux']);
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({pk: personpk}),
    };
    fetch(
        DNS +
        '/api/park/' +
        personpk,
        requestOptions,
    ).then(async (response) => {
        getParkList();
    });
  };


  const editPark = (e) => {
    e?.preventDefault();
    let buildingpklist = []
    showBatimentSelected.map((batiment) => {
      buildingpklist = buildingpklist.concat(batiment.value)
    })
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({
        name: showParkName,
        email: showParkEmail,
        pkBuilding: buildingpklist,
      }),
    };
    fetch(
        DNS +
        '/api/park/' + showApPk,
        requestOptions,
    ).then(async (response) => {
      const data = await response.json();
      if (data.status == 'WRONG') {
        alert('Impossible de saisir votre arrivée, assurez-vous que vous avez bien remplis les champs.');
      }
      getParkList();
      setShowModal2(false);
      resetValues();

    });
  };

  const getTodayDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();


    today = yyyy + '-' + mm + '-' + dd;
    const maxday = (yyyy + 1) + '-' + mm + '-' + dd;

    setTodayDate(today);
    setMaxdayDate(maxday);
  };

  const handleSubmit = (event) => {
    createPark()
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      'borderColor': state.isFocused ?
        '#ddd' : validated && showBatimentSelected.length == 0 ?
        'red' : '#ddd',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : validated && showBatimentSelected.length == 0 ?
          'red' : '#ddd',
      },
    }),
  };

  const setEditData = (park) => {
    // console.log("PARK DATA = ", park)
    let tmp = [];
    park.pkBuilding.map((elem) => {
      tmp = tmp.concat({'value': elem.id, 'label': elem.name});
    });
    setBatimentSelected(tmp)
    setShowModal2(true);
    setParkName(park.name);
    setParkEmail(park.email);
    // setName(park.last_name);
    // // setBatiment(park.email);
    setApPk((park.id));

  }

  
  const createPark = (e) => {
    e?.preventDefault();
    let buildingpklist = []
    showBatimentSelected.map((batiment) => {
      buildingpklist = buildingpklist.concat(batiment.value)
    })
    // console.log("pk = ", buildingpklist);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: {
        name: showParkName,
        email: showParkEmail,
        pkBuilding: buildingpklist,
      },
    };
    requestOptions.body = JSON.stringify(requestOptions.body);
    fetch(
        DNS +
        '/api/park/',
        requestOptions,
    )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 'WRONG') {
            alert('Impossible de rajouter votre Parc, assurez-vous que vous avez bien remplis les champs.');
          }
          getParkList();
          setShowModal(false);
        });
  };

  let content = null;

  // if (alist.error) {
  //   content = <p>ERROR: couldn't load person list properly.</p>;
  // }

  const listFilter = [];
  const headers = [
    {label: 'Nom du manager', key: 'name'},
    {label: 'Email du manager', key: 'email'},
    {label: 'Batiments du Parc', key: 'pkBuilding[0].name'},
  ];

  if (alist.length > 0) {
    content = alist
        .slice(0)
        .reverse()
        .filter((val) => {
              // console.log(typeof(val.end_time), val.end_time)
              if (searchTerm == '') {
                listFilter.push(val);
                return val;
              } else if (
                val.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.concat(val);
                return val;
              }
  
        })
        .map((park, key) => (
          <tr id="pk" value={park.id} key={key}>
            <td>{park.name}</td>
            <td>{park.email}</td>
            <td>{park.pkBuilding?.map((p, index) => index < park?.pkBuilding?.length - 1 ? p.name + ', ' : ' ' + p.name )}</td>
            <td className="td-actions text-right">
              <a
                className="btn-sm btn-round ml-1 mr-1  btn-warning"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setEditData(park)
                  // setShowModal2(true);
                  // setParkName(park.name);
                  // setParkEmail(park.email);
                  // setName(park.last_name);
                  // // setBatiment(park.email);
                  // setApPk(String(park.name).toLowerCase());
                  // console.log(building);
                  // showBatiment.map((elem) => {
                  //   if (elem.label == building.companyName) {
                  //     setBatimentSelected(elem);
                  //   }
                  // });
                }}
              >
                <i className="fas fa-edit"></i>
              </a>
              <a
                className="btn-sm btn-round btn-danger ml-1"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (
                    window.confirm(
                        'Souhaitez vous vraiment supprimer ce Parc ?',
                    )
                  ) {
                    deletePark(String(park.id).toLowerCase());
                  }
                }}
              >
                <i className="fas fa-times"></i>
              </a>
            </td>
          </tr>
        ));
  }

  // const checkTime = () => {
  //   if (typeof(showHour) == typeof('string')) {
  //     return (new Date('June 04, 2022 ' + showHour));
  //   } else {
  //     return (showHour);
  //   }
  // };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Liste des parcs</Card.Title>
              <p className="card-category">
                Consulter les managers de batiments existants
              </p>
              <div className="row" style={{paddingTop: '15px'}}>
                <Button
                  id="Ajouter"
                  className={'btn-round mr-1'}
                  onClick={() => {
                    setShowModal(true);
                    resetValues();
                    setDate(new Date());
                  }}
                >
                  Ajouter un nouveau parc
                </Button>{' '}
                <AsyncCSV
                  toExport={listFilter}
                  headers={headers}
                  sentence="Extraire les données"
                />
                <div id="custom-search-input">
                  <div className="input-group col-md-12">
                    <input
                      type="text"
                      className="form-control ml-5"
                      placeholder="Rechercher"
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                    <span className="input-group-btn btn btn-primary">
                      <span className=" fas fa-search"></span>
                    </span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th onClick={() => handleSortState('name')} style={{color: sortState == 'Reversename' ? "#ff0002" : sortState == 'name' ? "#5db5c7" : "#ffc106"}} className="border-0">Nom du manager {sortState == 'Reversename' ? <i className="fas fa-arrow-down"></i> : sortState == 'name' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('email')} style={{color: sortState == 'Reverseemail' ? "#ff0002" : sortState == 'email' ? "#5db5c7" : "#ffc106"}} className="border-0">Email du manager {sortState == 'Reverseemail' ? <i className="fas fa-arrow-down"></i> : sortState == 'email' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th className="border-0">Batiments du Parc</th> 
                    {sortState != 'neutral' ?
                    <div style={{marginLeft: "77%"}}>
                      <a
                      className="btn-sm btn-round ml-1 mr-1  btn-warning"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setSortState('neutral')
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </a>
                    </div>:
                      null
                    }                   
                  </tr>
                </thead>
                <tbody>{content}</tbody>
                <Modal
                  className="modal modal-primary"
                  onSubmit={(e) => editPark(e)}
                  show={showModal2}
                  onHide={() => setShowModal2(false)}
                  autoFocus={false}
                  enforceFocus={false}
                >
                  <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                      <i className="nc-icon fa fa-edit"></i>
                    </div>
                  </Modal.Header>

                  <div className="modal-body">
                    <p className="text-center">Modifer le parc ?</p>
                    <Form noValidate validated={validated}>
                      <Form.Group as={Row} controlId="formHorizontalPrénom">
                      <Form.Label column sm={4}>
                        Nom du manager:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          required
                          type="Prénom"
                          placeholder="Prénom & Nom"
                          value={showParkName}
                          onChange={(e) => setParkName(e.currentTarget.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalPrénom">
                      <Form.Label column sm={4}>
                        Email du manager:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          required
                          type="email"
                          placeholder="E-mail"
                          value={showParkEmail}
                          onChange={(e) => setParkEmail(e.currentTarget.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalCompany">
                      <Form.Label column sm={4}>
                        Batiment:
                      </Form.Label>
                      <Col sm={8}>
                        <Select
                          styles={customStyles}
                          isClearable={true}
                          isMulti
                          isSearchable={true}
                          components={animatedComponents}
                          value={showBatimentSelected}
                          options= {buildingList }
                          onChange={(e) =>
                            setBatimentSelected(
                              e == null ?
                              [] :
                              e,
                            )
                          }
                        />
                      </Col>
                    </Form.Group>
                      <Form.Group as={Row}>
                        <Button
                          className="btn-simple btn-round col-sm-6"
                          type="button"
                          variant="link"
                          onClick={() => setShowModal2(false)}
                        >
                          Fermer
                        </Button>
                        <Button className="btn-round col-sm-6" type="submit">
                          Modifier{' '}
                        </Button>{' '}
                      </Form.Group>
                    </Form>
                  </div>
                </Modal>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        className="modal modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={(e) => createPark(e)}
        autoFocus={false}
        enforceFocus={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-simple-add"></i>
          </div>
        </Modal.Header>
        <div className="modal-body">
          <p className="text-center">Ajouter un nouveau parc de batiments ?</p>
          <Form noValidate validated={validated} >
            <Form.Group as={Row} controlId="formHorizontalPrénom">
              <Form.Label column sm={4}>
                Nom du manager:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  required
                  type="Prénom"
                  placeholder="Prénom & Nom"
                  value={showParkName}
                  onChange={(e) => setParkName(e.currentTarget.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalPrénom">
              <Form.Label column sm={4}>
                Email du manager:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  required
                  type="email"
                  placeholder="E-mail"
                  value={showParkEmail}
                  onChange={(e) => setParkEmail(e.currentTarget.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalCompany">
              <Form.Label column sm={4}>
                Batiment:
              </Form.Label>
              <Col sm={8}>
                <Select
                  styles={customStyles}
                  isClearable={true}
                  isMulti
                  isSearchable={true}
                  components={animatedComponents}
                  value={showBatimentSelected}
                  options= {buildingList }
                  onChange={(e) =>
                    setBatimentSelected(
                      e == null ?
                      [] :
                      e,
                    )
                  }
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button
                className="btn-simple col-sm-6"
                type="button"
                variant="link"
                onClick={() => setShowModal(false)}
              >
                Fermer
              </Button>
              <Button className="btn-fill btn-round col-sm-6" type="submit">
                CRÉER{' '}
              </Button>{' '}
            </Form.Group>
          </Form>
        </div>
      </Modal>
    </Container>
  );
};

export default Park;
