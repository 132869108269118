import QRCode from 'qrcode.react';
import {
  Col,
} from 'react-bootstrap';
import React from 'react';

const AppointmentFormQR = (props) => {
  const QRCodeURL = props.QRCodeURL;
  if (props.external == 'false') {
    return (<Col md="6">

      <div className="image-bg">
      </div>
      <QRCode value={QRCodeURL} className="align-items-center" style={{
        margin: 'auto',
        display: 'block',
        marginTop: '30%',
        width: '200px',
        height: 'auto',
        padding: '10px',
        backgroundColor: 'white',
      }} />

    </Col>);
  }
  return ('');
};

export default AppointmentFormQR;
