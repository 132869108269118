import React, {useState, useEffect} from 'react';
import DNS from '../DNS/dns.js';
import '../../assets/css/appointment.css'; // main css file
import axios from 'axios';
// react-bootstrap components
import {
  Container,
  Row,
  Col,
  Modal,
} from 'react-bootstrap';

import AppointmentFormQR from './AppointmentFormQR.js';


const AppointmentForm = (props) => {
  const windowOrigin = window.location.origin;
  const windowPathname = window.location.pathname;
  const windowSearch = '?external=true&building=';
  const modalTexts = {
    'OK': {
      header: 'Formulaire envoyé',
      body: 'Votre correspondant a été prévenu de votre arrivée par mail',
    },
    'KO': {
      header: 'Rendez-vous introuvable',
      body: 'Votre rendez-vous est introuvable, veuillez vous rediriger à l\'accueil',
    },
  };

  const [modalText, setModalText] = useState('');
  const [external, setExternal] = useState(false);
  const [errors, setErrors] = useState([]);
  const [showErrors, setShowErrors] = useState(false);
  const [buildingNb, setBuildingNb] = useState('');
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [time, setTime] = useState('');
  const [comments, setComments] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [QRCodeURL, setQRCodeURL] = useState('');

  useEffect(() => {
    if (props.building !== '') {
      setBuildingNb(props.building);
    }
    if (props.external !== '') {
      setExternal(props.external);
    }
    setQRCodeURL(windowOrigin + windowPathname + windowSearch + props.building);
  }, []);

  const resetForm = () => {
    setFirstname('');
    setLastname('');
    setContactEmail('');
    setPhoneNumber('');
    setComments('');
    setTime('');
  };

  const sendForm = () => {
    const data = {
      'buildingNb': buildingNb,
      'firstname': firstname,
      'lastname': lastname,
      'contactEmail': contactEmail,
      'phoneNumber': phoneNumber,
      'comments': comments,
      'time': time,
    };
    axios.patch(DNS + '/appointment/', data)
        .then(function(response) {
          resetForm();
          setModalText(modalTexts['OK']);
          setShowModal(true);
        })
        .catch(function(error) {
          console.log(error);
          resetForm();
          setModalText(modalTexts['KO']);
          setShowModal(true);
        });
  };

  const AlertModal = (props) => {
    return (<Modal
      className="modal modal-primary"
      show={showModal}
      onHide={() => setShowModal(false)}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="justify-content-center">
        <div>
          <h2 style={{textAlign: 'center'}}>{modalText.header}</h2>
        </div>
      </Modal.Header>
      <div className="modal-body">
        <p className="text-center">{modalText.body}</p>
        <i className="justify-content-center" style={{display: 'flex', fontSize: '12px'}}>Cliquez n'importe où pour dissiper ce message</i>

      </div>
    </Modal>);
  };

  const submitForm = (e) => {
    e.preventDefault();
    const currentErrors = [];

    const isEmailValid = (str) => {
      const regexEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return regexEmail.test(str);
    };
    if (firstname === '') {
      currentErrors.push('Prénom requis.');
    }
    if (phoneNumber === '') {
      currentErrors.push('Numéro de téléphone requis.');
    }
    if (contactEmail === '') {
      currentErrors.push('Email requis.');
    }
    if (time === '') {
      currentErrors.push('Heure du rendez-vous requise.');
    } else if (!isEmailValid(contactEmail)) {
      currentErrors.push('Email valide requis.');
    }

    setErrors(currentErrors);
    setShowErrors(true);
    setTimeout(() => {
      console.log('set');
      setShowErrors(false);
    }, 5000);
    if (currentErrors.length === 0) {
      sendForm();
    }
  };

  const ErrorPlaceholder = () => {
    if (showErrors) {
      return (<div>
        <ul className="error">
          {errors.map((error) => {
            return (<li key={error}>{error}</li>);
          })}
        </ul>
      </div>);
    }
    return '';
  };

  return (
    <Container fluid>
      <Row style={{backgroundColor: 'black', color: 'white', overflowX: 'hidden'}}>
        <AppointmentFormQR QRCodeURL={QRCodeURL} external={external} />
        <Col md={(external == 'true') ? '12' : '6'}>
          <AlertModal />
          <section className="content" style={{width: '100%', margin: '0'}}>
            <div className="appointment contact" style={{paddingTop: '20px'}}>
              <div className="col-12 wow fadeInUp"
                style={{textAlign: 'center', visibility: 'visible', animationName: 'fadeInUp'}}>
                <div className="titles" style={{marginBottom: '0'}}>
                  <h2
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      fontSize: '2.5em',
                      color: '#ffdb09',
                    }}>
                    Bienvenue</h2>
                  <p className="title ceraPro">Saisissez les informations ci-dessous pour prévenir de votre
                    arrivée.
                  </p>
                </div>
              </div>

              <ErrorPlaceholder />

              <div className="col-md-12 col-sm-12 col-12 wow fadeIn ceraPro"
                style={{visibility: 'visible', animationName: 'fadeIn'}}>
                <form name="demande" onSubmit={submitForm}>
                  <div className="row">

                    <div className="form-group col-sm-12 col-12">
                      <label className="input-label required">Votre prénom *</label>

                      <input type="text" className=" form-control"
                        value={firstname}
                        onChange={(event) => {
                          setFirstname(event.target.value);
                        }}
                        placeholder="ex: Jean" />
                    </div>

                    <div className="form-group col-sm-12 col-12">

                      <label className="input-label required">Votre nom </label>
                      <input type="text" className=" form-control"
                        value={lastname}
                        onChange={(event) => {
                          setLastname(event.target.value);
                        }}
                        placeholder="ex: Dupond" />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12 col-12">
                      <label className="input-label required">Heure du rendez-vous *</label>

                      <input type="time" className=" form-control"
                        value={time}
                        onChange={(event) => {
                          setTime(event.target.value);
                        }} />
                    </div>
                  </div>

                  <div className="row">
                    <div className="form-group col-sm-12 col-12">
                      <label className="input-label required">Email de la personne
                        rencontrée *</label>
                      <input
                        value={contactEmail}
                        onChange={(event) => {
                          setContactEmail(event.target.value);
                        }}
                        className="form-control"
                        placeholder="ex: dupond@domaine.com" />
                    </div>
                    <div className="form-group col-sm-12 col-12">
                      <label className="control-label required">Votre numéro de
                        téléphone *</label>

                      <input type="tel" className=" form-control"
                        value={phoneNumber}
                        onChange={(event) => {
                          setPhoneNumber(event.target.value);
                        }}
                        placeholder="ex: 0601020304" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="form-group col-12">
                      <label className="control-label">Saisissez un
                        commentaire</label>

                      <textarea className=" form-control" placeholder="Commentaire"
                        value={comments}
                        onChange={(event) => {
                          setComments(event.target.value);
                        }}
                        rows="3" noValidate="novalidate"></textarea>
                    </div>
                  </div>
                  <div className="form-group col-12 text-center">
                    <button id="submit" type="submit">Envoyer</button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </Col>
      </Row>
    </Container>
  );
};
export default AppointmentForm;
