import 'assets/css/HAGD-Custom.css';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import axios from 'axios';
import AsyncCSV from 'components/csvExport';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {fr} from 'react-date-range/src/locale/index';
import DNS from '../components/DNS/dns.js';
import Moment from 'react-moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import TimePickerComponent from 'components/DateTime/TimePicker';
import DatePickerComponent from 'components/DateTime/DatePicker';

// react-bootstrap components
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
} from 'react-bootstrap';
import {DateRange} from 'react-date-range';

const Building = () => {
  const token = useSelector((state) => state.token);
  const currentBuilding = useSelector((state) => state.currentBuilding);
  const user = useSelector((state) => state.user);
  const animatedComponents = makeAnimated();

  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModalComments, setShowModalComments] = React.useState(false);
  const [showBuildingName, setBuildingName] = useState('');
  const [showBuildingSlug, setBuildingSlug] = useState('');

  const [showComments, setComments] = useState('');
  const [showHour, setHour] = useState('');
  const [showDate, setDate] = useState('');
  const [showName, setName] = useState('');
  const [showEmail, setEmail] = useState('');
  const [showApPk, setApPk] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [sortState, setSortState] = useState('neutral')
  const [ogList, setOgList] = useState([])


  const [showBatiment, setBatiment] = useState([]);
  const [showPersonHour, setPersonHour] = useState([]);
  const [showBatimentSelected, setBatimentSelected] = useState([]);

  const [showDisplayDateRange, setDisplayDateRange] = useState('none');
  const [showTodayDate, setTodayDate] = useState('');
  const [showMaxdayDate, setMaxdayDate] = useState('');
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [alist, setList] = useState([]);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };
  useEffect(() => {
    // getBatiment();
    getTodayDate();
    getBuildingList()
  }, []);


  useEffect(() => {
    let tmp = ogList
    console.log(ogList)

    if (sortState == 'neutral') {
      setList(ogList)
    }


    if (sortState == 'Reversebuilding') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let nameA = a?.name.toLowerCase();
        let nameB = b?.name.toLowerCase();

        return nameA.localeCompare(nameB);
    });
      setList(toSort)
    }

    if (sortState == 'building') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let nameA = a?.name.toLowerCase();
        let nameB = b?.name.toLowerCase();

        return nameB.localeCompare(nameA);
    });
      setList(toSort)
    }

    if (sortState == 'Reverseemail') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let emailA = a?.email.toLowerCase();
        let emailB = b?.email.toLowerCase();

        return emailA.localeCompare(emailB);
      })
      setList(toSort)
    }
    if (sortState == 'email') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let emailA = a?.email.toLowerCase();
        let emailB = b?.email.toLowerCase();

        return emailB.localeCompare(emailA);
        })
      setList(toSort)
    }

    if (sortState == 'Reversecode') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let codeA = a?.confirm_code;
        let codeB = b?.confirm_code;
  
        return codeA - codeB;
      })
      setList(toSort)
    }
    if (sortState == 'code') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let codeA = a?.confirm_code;
        let codeB = b?.confirm_code;
  
        return codeB - codeA;
        })
      setList(toSort)
    }

    if (sortState == 'Reverseid') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let idA = a?.id;
        let idB = b?.id;
  
        return idA - idB;
      })
      setList(toSort)
    }

    if (sortState == 'id') {
      let toSort = tmp.slice(0).sort((a, b) => {
        let idA = a?.id;
        let idB = b?.id;
  
        return idB - idA;
        })
      setList(toSort)
    }

  }, [sortState])

  const handleSortState = (sortingOrder) => {

    if (sortingOrder == 'email' ) {
      if (sortState == 'email') {
        setSortState('Reverseemail')
      } else {
        setSortState('email')
      }
    }

    if (sortingOrder == 'building' ) {
      if (sortState == 'building') {
        setSortState('Reversebuilding')
      } else {
        setSortState('building')
      }
    }

    if (sortingOrder == 'code' ) {
      if (sortState == 'code') {
        setSortState('Reversecode')
      } else {
        setSortState('code')
      }
    }

    if (sortingOrder == 'id' ) {
      if (sortState == 'id') {
        setSortState('Reverseid')
      } else {
        setSortState('id')
      }
    }
  }

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };

  const formatTimeRange = (timeRange) => {
    const [startTimeStr] = timeRange?.split(' - ');
    const [hours, minutes, seconds] = startTimeStr?.split(':');
    return new Date(0, 0, 0, hours, minutes, seconds);
  }

  const resetValues = () => {
    setValidated(false);
    setBuildingName('');
    setEmail('')
    setApPk('');
  };


  const getBuildingList = () => {
    axios.get(
        DNS +
        '/api/building/' ,
        requestOptionsAxiosGet,
    ).then(async (response) => {
          let tmp = [];
          response.data.map((elem) => {
            tmp = tmp.concat(elem);
          });
          setList(tmp);
          setOgList(response.data) 
          console.log(tmp)
    });
  };

  const deleteBuilding = (personpk) => {
    console.log(token['tokenRedux']);
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({pk: personpk}),
    };
    fetch(
        DNS +
        '/api/building/' +
        personpk,
        requestOptions,
    ).then(async (response) => {
        getBuildingList();
    });
  };


  const editBuilding = () => {
    console.log(showApPk)
    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({
        name: showBuildingName,
        email: showEmail
      }),
    };
    fetch(
        DNS +
        '/api/building/' + showApPk,
        requestOptions,
    ).then(async (response) => {
      const data = await response.json();
      if (data.status == 'WRONG') {
        alert('Impossible de saisir votre arrivée, assurez-vous que vous avez bien remplis les champs.');
      }
      getBuildingList();
      setShowModal2(false);
      resetValues();

    });
  };

  const getTodayDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();


    today = yyyy + '-' + mm + '-' + dd;
    const maxday = (yyyy + 1) + '-' + mm + '-' + dd;

    setTodayDate(today);
    setMaxdayDate(maxday);
  };

  const handleSubmit = (event) => {
    createBuilding()
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      'borderColor': state.isFocused ?
        '#ddd' : validated && showBatimentSelected.length == 0 ?
        'red' : '#ddd',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : validated && showBatimentSelected.length == 0 ?
          'red' : '#ddd',
      },
    }),
  };

  
  const createBuilding = (e) => {
    e?.preventDefault();

    console.log("pk = ", showBatimentSelected);
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: {
        name: showBuildingName,
        email: showEmail
      },
    };
    requestOptions.body = JSON.stringify(requestOptions.body);
    fetch(
        DNS +
        '/api/building/',
        requestOptions,
    )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 'WRONG') {
            alert('Impossible de saisir votre Batiment, assurez-vous que vous avez bien remplis le champs.');
          }
          getBuildingList();
          setShowModal(false);
        });
  };

  let content = null;

  // if (alist.error) {
  //   content = <p>ERROR: couldn't load person list properly.</p>;
  // }

  const listFilter = [];
  const headers = [
    {label: 'Nom du batiment', key: 'name'},
    {label: 'Email du manager', key: 'email'},
    {label: 'Id', key: 'id'},
    {label: 'Code du batiment', key: 'confirm_code'},
  ];

  if (alist.length > 0) {
    content = alist
        .slice(0)
        .reverse()
        .filter((val) => {
              console.log(typeof(val.end_time), val.end_time)
              if (searchTerm == '') {
                listFilter.push(val);
                return val;
              } else if (
                String(val.name).toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.concat(val);
                return val;
              } else if (
                String(val.slug).toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.concat(val);
                return val;
              } 
  
        })
       .map((building, key ) => (
          <tr id="pk" value={building.id} key={key}>
            <td>{building.name}</td>
            <td>{building.email}</td>
            <td>{building.id}</td>
            <td>{building.confirm_code}</td>
            <td className="td-actions text-right">
              <a
                className="btn-sm btn-round ml-1 mr-1  btn-warning"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setShowModal2(true);
                  setBuildingName(building.name);
                  setEmail(building.email)
                  // setBuildingSlug(building.slug)
                  // setName(building.last_name);
                  // // setBatiment(building.email);
                  setApPk(String(building.slug).toLowerCase());
                  // console.log(building);
                  // showBatiment.map((elem) => {
                  //   if (elem.label == building.companyName) {
                  //     setBatimentSelected(elem);
                  //   }
                  // });
                }}
              >
                <i className="fas fa-edit"></i>
              </a>
              <a
                className="btn-sm btn-round btn-danger ml-1"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (
                    window.confirm(
                        'Souhaitez vous vraiment supprimer ce Batiment ? \n Attention: les plages horaires liées à ce batiment seront également supprimées.',
                    )
                  ) {
                    deleteBuilding(String(building.slug).toLowerCase());
                  }
                }}
              >
                <i className="fas fa-times"></i>
              </a>
            </td>
          </tr>
        ));
  }

  const checkTime = () => {
    if (typeof(showHour) == typeof('string')) {
      return (new Date('June 04, 2022 ' + showHour));
    } else {
      return (showHour);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Liste des batiments</Card.Title>
              <p className="card-category">
                Consulter les batiments existants
              </p>
              <div className="row" style={{paddingTop: '15px'}}>
                <Button
                  id="Ajouter"
                  className={'btn-round mr-1'}
                  onClick={() => {
                    setShowModal(true);
                    resetValues();
                    setDate(new Date());
                  }}
                >
                  Ajouter un nouveau batiment
                </Button>{' '}
                <AsyncCSV
                  toExport={listFilter}
                  headers={headers}
                  sentence="Extraire les données"
                />
                <div id="custom-search-input">
                  <div className="input-group col-md-12">
                    <input
                      type="text"
                      className="form-control ml-5"
                      placeholder="Rechercher"
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                    <span className="input-group-btn btn btn-primary">
                      <span className=" fas fa-search"></span>
                    </span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th onClick={() => handleSortState('building')} style={{color: sortState == 'Reversebuilding' ? "#ff0002" : sortState == 'building' ? "#5db5c7" : "#ffc106"}} className="border-0">Nom du batiment {sortState == 'Reversebuilding' ? <i className="fas fa-arrow-down"></i> : sortState == 'building' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('email')} style={{color: sortState == 'Reverseemail' ? "#ff0002" : sortState == 'email' ? "#5db5c7" : "#ffc106"}} className="border-0">Email du manager {sortState == 'Reverseemail' ? <i className="fas fa-arrow-down"></i> : sortState == 'email' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('id')} style={{color: sortState == 'Reverseid' ? "#ff0002" : sortState == 'id' ? "#5db5c7" : "#ffc106"}} className="border-0">Id {sortState == 'Reverseid' ? <i className="fas fa-arrow-down"></i> : sortState == 'id' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('code')} style={{color: sortState == 'Reversecode' ? "#ff0002" : sortState == 'code' ? "#5db5c7" : "#ffc106"}} className="border-0">Code du batiment {sortState == 'Reversecode' ? <i className="fas fa-arrow-down"></i> : sortState == 'code' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>                    
                    {sortState != 'neutral' ?
                    <div style={{marginLeft: "80%"}}>
                      <a
                      className="btn-sm btn-round ml-1 mr-1  btn-warning"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setSortState('neutral')
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </a>
                    </div>:
                      null
                    }
                  </tr>
                </thead>
                <tbody>{content}</tbody>
                <Modal
                  className="modal modal-primary"
                  onSubmit={() => editBuilding()}
                  show={showModal2}
                  onHide={() => setShowModal2(false)}
                  autoFocus={false}
                  enforceFocus={false}
                >
                  <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                      <i className="nc-icon fa fa-edit"></i>
                    </div>
                  </Modal.Header>

                  <div className="modal-body">
                    <p className="text-center">Modifer le batiment?</p>
                    <Form noValidate validated={validated}>
                      <Form.Group as={Row} controlId="formHorizontalPrénom">
                      <Form.Label column sm={4}>
                        Nom du batiment:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          required
                          type="Prénom"
                          placeholder="Nom du batiment"
                          value={showBuildingName}
                          onChange={(e) => setBuildingName(e.currentTarget.value)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="formHorizontalEmail">
                      <Form.Label column sm={4}>
                        Email du manager:
                      </Form.Label>
                      <Col sm={8}>
                        <Form.Control
                          required
                          type="Prénom"
                          placeholder="Manager du batiment"
                          value={showEmail}
                          onChange={(e) => setEmail(e.currentTarget.value)}
                        />
                      </Col>
                    </Form.Group>
                      <Form.Group as={Row}>
                        <Button
                          className="btn-simple btn-round col-sm-6"
                          type="button"
                          variant="link"
                          onClick={() => setShowModal2(false)}
                        >
                          Fermer
                        </Button>
                        <Button className="btn-round col-sm-6" type="submit">
                          Modifier{' '}
                        </Button>{' '}
                      </Form.Group>
                    </Form>
                  </div>
                </Modal>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        className="modal modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        onSubmit={(e) => createBuilding(e)}
        autoFocus={false}
        enforceFocus={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-simple-add"></i>
          </div>
        </Modal.Header>
        <div className="modal-body">
          <p className="text-center">Ajouter un nouveau batiment ?</p>
          <Form noValidate validated={validated} >
            <Form.Group as={Row} controlId="formHorizontalPrénom">
              <Form.Label column sm={4}>
                Nom du batiment:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  required
                  type="Prénom"
                  placeholder="Nom du batiment"
                  value={showBuildingName}
                  onChange={(e) => setBuildingName(e.currentTarget.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalEmail">
              <Form.Label column sm={4}>
                Email du manager:
              </Form.Label>
              <Col sm={8}>
                <Form.Control
                  required
                  type="Prénom"
                  placeholder="Manager du batiment"
                  value={showEmail}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button
                className="btn-simple col-sm-6"
                type="button"
                variant="link"
                onClick={() => setShowModal(false)}
              >
                Fermer
              </Button>
              <Button className="btn-fill btn-round col-sm-6" type="submit">
                CRÉER{' '}
              </Button>{' '}
            </Form.Group>
          </Form>
        </div>
      </Modal>
    </Container>
  );
};

export default Building;
