const initialState = {
  tokenRedux: '',
  currentbuildingRedux: '',
  buildingRedux: '',
};

const Token = (state = initialState, action) => {
  switch (action.type) {
    case ('TOKEN_REDUX'):
      return {
        ...state,
        tokenRedux: action.value,
      };

    default:
      return state;
  }
};

export default Token;
