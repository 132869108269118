import 'assets/css/HAGD-Custom.css';
import React, {useEffect} from 'react';
import {useSelector} from 'react-redux';
import {useState} from 'react';
import axios from 'axios';
import AsyncCSV from 'components/csvExport';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {fr} from 'react-date-range/src/locale/index';
import DNS from '../components/DNS/dns.js';
import Moment from 'react-moment';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import TimePickerComponent from 'components/DateTime/TimePicker';
import DatePickerComponent from 'components/DateTime/DatePicker';

// react-bootstrap components
import {
  Button,
  Card,
  Modal,
  Form,
  Table,
  Container,
  Row,
  Col,
  Dropdown,
} from 'react-bootstrap';
import {DateRange} from 'react-date-range';

/**
 * This function handle the style and js in the HourList's page
 * @function HourList
 * @return {HTML} HourList's page
 */
const HourList = () => {
  const token = useSelector((state) => state.token);
  const currentBuilding = useSelector((state) => state.currentBuilding);
  const user = useSelector((state) => state.user);
  const animatedComponents = makeAnimated();

  const [showModal, setShowModal] = React.useState(false);
  const [showModal2, setShowModal2] = React.useState(false);
  const [showModalComments, setShowModalComments] = React.useState(false);
  const [showPrenom, setprenom] = useState('');
  const [showComments, setComments] = useState('');
  const [showHour, setHour] = useState('');
  const [showHour2, setHour2] = useState('');
  const [showDate, setDate] = useState('');
  const [showName, setName] = useState('');
  const [showEmail, setEmail] = useState('');
  const [showApPk, setApPk] = useState('');
  const [searchTerm, setSearchTerm] = useState('');


  const [showHourList, setHourList] = useState([]);
  const [sortState, setSortState] = useState('neutral')
  const [ogList, setOgList] = useState([])
  const [showPersonList, setPersonList] = useState([]);
  const [showBatiment, setBatiment] = useState([]);
  const [showBatimentSelected, setBatimentSelected] = useState([]);
  const [showPersonSelected, setPersonSelected] = useState([]);

  const [showDisplayDateRange, setDisplayDateRange] = useState('none');
  const [showTodayDate, setTodayDate] = useState('');
  const [showMaxdayDate, setMaxdayDate] = useState('');
  const [validated, setValidated] = useState(false);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ]);
  const [alist, setList] = useState(
    {
      data: null,
      error: false,
    },
    [],
);
  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };
  useEffect(() => {
    getBatiment();
    getTodayDate();
    getEmployees();
  }, []);

  useEffect(() => {
    let tmp = ogList
    console.log(ogList)

    if (sortState == 'neutral') {
      setHourList(ogList)
    }

    if (sortState == 'Reverseperson') {
      let toSort = tmp.slice(0).sort((a, b) => {
        if (a.pkPerson != null && b.pkPerson != null ) {
          let nameA = (a.pkPerson.first_name + ' ' + a.pkPerson.last_name).toLowerCase();
          let nameB = (b.pkPerson.first_name + ' ' + b.pkPerson.last_name).toLowerCase();
          return nameA.localeCompare(nameB);
        }
    });
    setHourList(toSort)
    }

    if (sortState == 'person') {
      let toSort = tmp.slice(0).sort((a, b) => {
      if (a.pkPerson != null && b.pkPerson != null ) {
        let nameA = (a.pkPerson.first_name + ' ' + a.pkPerson.last_name).toLowerCase();
        let nameB = (b.pkPerson.first_name + ' ' + b.pkPerson.last_name).toLowerCase();
        return nameB.localeCompare(nameA);
      }
    });
    setHourList(toSort)
  }

    if (sortState == 'Reversebuilding') {
      console.log("shit")
      let toSort = tmp.slice(0).sort((a, b) => {
        let nameA = a?.pkBuilding?.name.toLowerCase();;
        let nameB = b?.pkBuilding?.name.toLowerCase();;
        return nameA.localeCompare(nameB);
    });
      setHourList(toSort)
    }

    if (sortState == 'building') {
      console.log("gang")
      let toSort = tmp.slice(0).sort((a, b) => {
        let nameA = a?.pkBuilding?.name.toLowerCase();;
        let nameB = b?.pkBuilding?.name.toLowerCase();;
        return nameB.localeCompare(nameA);
    });
      setHourList(toSort)
    }

    if (sortState == 'Reversebegin') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin;
        let timeB = b?.begin;
  
        return timeA?.localeCompare(timeB);
      })
      setHourList(toSort)
    }
    if (sortState == 'begin') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin;
        let timeB = b?.begin;
  
        return timeB?.localeCompare(timeA);
        })
      setHourList(toSort)
    }

    if (sortState == 'Reverseend') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin_max;
        let timeB = b?.begin_max;
  
        return timeA?.localeCompare(timeB);
      })
      setHourList(toSort)
    }
    if (sortState == 'end') {
      let toSort = tmp.slice(0).sort((a, b) => { 
        let timeA = a?.begin_max;
        let timeB = b?.begin_max;
  
        return timeB?.localeCompare(timeA);
        })
      setHourList(toSort)
    }

  }, [sortState])

  const handleSortState = (sortingOrder) => {
    if (sortingOrder == 'person' ) {
      if (sortState == 'person') {
        setSortState('Reverseperson')
      } else {
        setSortState('person')
      }
    }

    if (sortingOrder == 'begin' ) {
      if (sortState == 'begin') {
        setSortState('Reversebegin')
      } else {
        setSortState('begin')
      }
    }

    if (sortingOrder == 'end' ) {
      if (sortState == 'end') {
        setSortState('Reverseend')
      } else {
        setSortState('end')
      }
    }

    if (sortingOrder == 'building' ) {
      if (sortState == 'building') {
        setSortState('Reversebuilding')
      } else {
        setSortState('building')
      }
    }
  }

  const requestOptionsAxiosGet = {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'token ' + token['tokenRedux'],
    },
  };

  const formatTimeRange = (timeRange) => {
    const [startTimeStr] = timeRange?.split(' - ');
    const [hours, minutes, seconds] = startTimeStr?.split(':');
    return new Date(0, 0, 0, hours, minutes, seconds);
  }


  const getBatiment = () => {
    axios
        .get(
            DNS +
          '/api/building/',
            requestOptionsAxiosGet,
        )
        .then((response) => {
          // console.log(response)
          let tmp = [];
          response.data.map((elem) => {
            tmp = tmp.concat({'value': elem.id, 'label': elem.name});
          });
          setBatiment(tmp);
        });
  };

  const resetValues = () => {
    setValidated(false);
    setName('');
    setHour('');
    setHour2('');
    setBatimentSelected('')
    setDate('');
    setprenom('');
    setEmail('');
    setComments('');
    setApPk('');
  };


  const getEmployees = () => {
    let tmp;
    let tmp2 = [];
    axios.get(
        DNS +
        '/api/person/' ,
        requestOptionsAxiosGet,
    ).then(async (response) => {
      if (response.data) {
        console.log("employees array: ", response.data)
        response.data.map((elem) => {
          tmp2 = tmp2.concat({'value': elem.id, 'label': elem.first_name + " " + elem.last_name});
        });
        setPersonList(tmp2)
        tmp = response.data
        axios.get(
        DNS +
        '/api/hour/' ,
        requestOptionsAxiosGet,
    ).then(async (response) => {
      if (response.data) {
        tmp.filter((person) => {
          response.data.map((hour) => {
            if (hour.pkPerson == person.id) {
              hour.firstName = person.first_name
              hour.lastName = person.last_name
          }})
        })
        console.log(response.data)
        setHourList(response.data)
        setOgList(response.data) 
      }
    })
        return;
      } else {
        alert("ERREUR: Requête invalide")
        return;
      }
    });
  };

  const deleteHour = (personpk) => {
    console.log(token['tokenRedux']);
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({pk: personpk}),
    };
    fetch(
        DNS +
        '/api/hour/edit-delete/' +
        personpk,
        requestOptions,
    ).then(async (response) => {
        getEmployees();
    });
  };


  const editHour = () => {
    let today = new Date()
    let time;
    let time2;

    time = typeof(showHour) == typeof(new Date()) ? (showHour.getHours() < 10 ? "0" + showHour.getHours() : showHour.getHours()) + ':' + (showHour.getMinutes() < 10 ? "0" + showHour.getMinutes() : showHour.getMinutes()) + ":00" : showHour;
    // let timetmp =  new Date(showHour.setTime(oldDateObj.getTime() + (5 * 60 * 1000)));
    today = today.getFullYear() + "-" + (today.getMonth() < 10 ? "0" + today.getMonth() : today.getMonth()) + "-" + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate())

    time2 = typeof(showHour2) == typeof(new Date()) ? (showHour2.getHours() < 10 ? "0" + showHour2.getHours() : showHour2.getHours()) + ':' + (showHour2.getMinutes() < 10 ? "0" + showHour2.getMinutes() : showHour2.getMinutes()) + ":00" : showHour2;


    const requestOptions = {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: JSON.stringify({
        pkPerson: showPersonSelected.value,
        // person_status: 
        // <'waiting',
        begin: time,
        end: "00:00:00",
        date: today,
        begin_max: time2,
        end_max: '00:00:00',
        // confirm_code: showEmail,
      }),
    };
    fetch(
        DNS +
        '/api/hour/edit-delete/' + showApPk,
        requestOptions,
    ).then(async (response) => {
      const data = await response.json();
      if (data.status == 'WRONG') {
        alert('Impossible de saisir votre arrivée, assurez-vous que vous avez bien remplis les champs.');
      }
      getEmployees();
      setShowModal2(false);
      resetValues();

    });
  };

  const getTodayDate = () => {
    let today = new Date();
    const dd = String(today.getDate()).padStart(2, '0');
    const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const yyyy = today.getFullYear();


    today = yyyy + '-' + mm + '-' + dd;
    const maxday = (yyyy + 1) + '-' + mm + '-' + dd;

    setTodayDate(today);
    setMaxdayDate(maxday);
  };

  const handleSubmit = (event) => {
    event.preventDefault()
    createHour()
  };

  const customStyles = {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      'borderColor': state.isFocused ?
        '#ddd' : validated && showPersonSelected.length == 0 ?
        'red' : '#ddd',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ?
          '#ddd' : validated && showPersonSelected.length == 0 ?
          'red' : '#ddd',
      },
    }),
  };

  const createHour = (e) => {
    let today = new Date()
    let time;
    let time2;
    let oldDateObj = new Date(showHour)
    time = typeof(showHour) == typeof(new Date()) ? (showHour.getHours() < 10 ? "0" + showHour.getHours() : showHour.getHours()) + ':' + (showHour.getMinutes() < 10 ? "0" + showHour.getMinutes() : showHour.getMinutes()) + ":00" : showHour;
    // let timetmp =  new Date(showHour.setTime(oldDateObj.getTime() + (5 * 60 * 1000)));
    today = today.getFullYear() + "-" + (today.getMonth() < 10 ? "0" + today.getMonth() : today.getMonth()) + "-" + (today.getDate() < 10 ? "0" + today.getDate() : today.getDate())

    time2 = typeof(showHour2) == typeof(new Date()) ? (showHour2.getHours() < 10 ? "0" + showHour2.getHours() : showHour2.getHours()) + ':' + (showHour2.getMinutes() < 10 ? "0" + showHour2.getMinutes() : showHour2.getMinutes()) + ":00" : showHour2;

    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'token ' + token['tokenRedux'],
      },
      body: {
        pkPerson: showPersonSelected.value,
        // person_status: 
        // <'waiting',
        begin: time,
        // end: "00:00:00",
        pkBuilding: showBatimentSelected.value,
        begin_max: time2,

        // confirm_code: showEmail,
      },
    };
    console.log("BODY BODY  = == = ", requestOptions.body)
    requestOptions.body = JSON.stringify(requestOptions.body);
    fetch(
        DNS +
        '/api/hour/',
        requestOptions,
    )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 'WRONG') {
            alert('Impossible de saisir votre arrivée, assurez-vous que vous avez bien remplis les champs.');
          }
          getEmployees();
          resetValues();
          setShowModal(false);
        }).catch((error) => {
          alert("ERREUR: Configuration d'horaire échouée veuillez ressayer.\n RAPPEL: Il est impossible de configurer une horaire pour un collaborateur qui possède déja une autre configuration horaire.")
        });
  };

  let content = null;

  // if (alist.error) {
  //   content = <p>ERROR: couldn't load person list properly.</p>;
  // }

  const listFilter = [];
  const headers = [
    {label: 'Batiment', key: 'pkBuilding.name'},
    {label: 'Prénom du Collaborateur affecté', key: 'pkPerson.first_name'},
    {label: 'Nom du Collaborateur affecté', key: 'pkPerson.last_name'},
    {label: 'Début de plage horaire', key: 'begin'},
    {label: 'Fin de plage horaire', key: 'begin_max'},
  ];

  if (showHourList.length > 0) {
    content = showHourList
        .slice(0)
        .reverse()
        .filter((val) => {
              // console.log(typeof(val.end_time), val.end_time)
              if (searchTerm == '') {
                listFilter.push(val);
                return val;
              } else if (
                val.pkBuilding.name.toLowerCase().includes(searchTerm.toLowerCase())
              ) {
                listFilter.push(val);
                return val;
              } else if (
                val.begin.includes(searchTerm)
              ) {
                listFilter.push(val);
                return val;
              } else if (
                val.begin_max.includes(searchTerm)
              ) {
                listFilter.push(val);
                return val;
              } else if (val.pkPerson != null) {
                if (val.pkPerson.first_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                  listFilter.push(val);
                  return val;
                } else if (val.pkPerson.last_name.toLowerCase().includes(searchTerm.toLowerCase())) {
                  listFilter.push(val);
                  return val;
                }
              }
  
        })
        .map((hourperson, key) => (
          <tr id="pk" value={hourperson.id} key={key}>
            <td>{hourperson.pkBuilding.name}</td>
            <td>{hourperson.pkPerson != null ? hourperson.pkPerson.first_name + ' ' + hourperson.pkPerson.last_name : ''}</td>
            <td>{hourperson.begin}</td>
            <td>{hourperson.begin_max}</td>
            {/* <td>{"e"}</td>
            <td>{"f"}</td> */}
            <td className="td-actions text-right">
              <a
                className="btn-sm btn-round ml-1 mr-1  btn-warning"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  setShowModal2(true);
                  // setBatiment(person.email);
                  // setHour(hourperson.begin)
                  setApPk(hourperson.id);
                  // showBatiment.map((elem) => {
                  //   if (elem.label == person.companyName) {
                  //     setPersonSelected(elem);
                  //   }
                  // });
                }}
              >
                <i className="fas fa-edit"></i>
              </a>
              <a
                className="btn-sm btn-round btn-danger ml-1"
                style={{cursor: 'pointer'}}
                onClick={() => {
                  if (
                    window.confirm(
                        'Souhaitez vous vraiment supprimer cette plage horaire ?',
                    )
                  ) {
                    deleteHour(hourperson.id);
                  }
                }}
              >
                <i className="fas fa-times"></i>
              </a>
            </td>
          </tr>
        ));
  }

  const checkTime = () => {
    if (typeof(showHour) == typeof('string')) {
      return (new Date('June 04, 2022 ' + showHour));
    } else {
      return (showHour);
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col md="12">
          <Card className="strpied-tabled-with-hover">
            <Card.Header>
              <Card.Title as="h4">Liste des horaires</Card.Title>
              <p className="card-category">
                Consulter les horaires
              </p>
              <div className="row" style={{paddingTop: '15px'}}>
                <Button
                  id="Ajouter"
                  className={'btn-round mr-1'}
                  onClick={() => {
                    setShowModal(true);
                    resetValues();
                    setDate(new Date());
                  }}
                >
                  Configurer une nouvelle horaire
                </Button>{' '}
                <AsyncCSV
                  toExport={listFilter}
                  headers={headers}
                  sentence="Extraire les données"
                />
                <div id="custom-search-input">
                  <div className="input-group col-md-12">
                    <input
                      type="text"
                      className="form-control ml-5"
                      placeholder="Rechercher"
                      onChange={(event) => {
                        setSearchTerm(event.target.value);
                      }}
                    />
                    <span className="input-group-btn btn btn-primary">
                      <span className=" fas fa-search"></span>
                    </span>
                  </div>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive">
              <Table className="table-hover table-striped">
                <thead>
                  <tr>
                    <th onClick={() => handleSortState('building')} style={{color: sortState == 'Reversebuilding' ? "#ff0002" : sortState == 'building' ? "#5db5c7" : "#ffc106"}} className="border-0">Batiment {sortState == 'Reversebuilding' ? <i className="fas fa-arrow-down"></i> : sortState == 'building' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('person')} style={{color: sortState == 'Reverseperson' ? "#ff0002" : sortState == 'person' ? "#5db5c7" : "#ffc106"}} className="border-0">Collaborateur affecté {sortState == 'Reverseperson' ? <i className="fas fa-arrow-down"></i> : sortState == 'person' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('begin')} style={{color: sortState == 'Reversebegin' ? "#ff0002" : sortState == 'begin' ? "#5db5c7" : "#ffc106"}} className="border-0">Début de plage horaire {sortState == 'Reversebegin' ? <i className="fas fa-arrow-down"></i> : sortState == 'begin' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    <th onClick={() => handleSortState('end')} style={{color: sortState == 'Reverseend' ? "#ff0002" : sortState == 'end' ? "#5db5c7" : "#ffc106"}} className="border-0">Fin de plage horaire {sortState == 'Reverseend' ? <i className="fas fa-arrow-down"></i> : sortState == 'end' ? <i className="fas fa-arrow-up"></i> : <i className="fas fa-arrow-right"></i>}</th>
                    {/* <th className="border-0">Heure soir</th>
                    <th className="border-0">Heure soir max</th> */}
                    {sortState != 'neutral' ?
                    <div style={{marginLeft: "75%"}}>
                      <a
                      className="btn-sm btn-round ml-1 mr-1  btn-warning"
                      style={{cursor: 'pointer'}}
                      onClick={() => {
                        setSortState('neutral')
                      }}
                    >
                      <i className="fas fa-times"></i>
                    </a>
                    </div>:
                      null
                    }
                  </tr>
                </thead>
                <tbody>{content}</tbody>
                <Modal
                  className="modal modal-primary"
                  onSubmit={() => editHour()}
                  show={showModal2}
                  onHide={() => setShowModal2(false)}
                  autoFocus={false}
                  enforceFocus={false}
                >
                  <Modal.Header className="justify-content-center">
                    <div className="modal-profile">
                      <i className="nc-icon fa fa-edit"></i>
                    </div>
                  </Modal.Header>

                  <div className="modal-body">
                    <p className="text-center">Modifer cette plage horaire ?</p>
                    <Form noValidate validated={validated}>
                      {/* <Form.Group as={Row} controlId="formHorizontalCompany">
                        <Form.Label column sm={4}>
                          Collaborateur:
                        </Form.Label>
                        <Col sm={8}>
                          <Select
                            styles={customStyles}
                            isClearable={true}
                            isSearchable={true}
                            components={animatedComponents}
                            value={showPersonSelected}
                            options= { showPersonList }
                            onChange={(e) =>
                              setPersonSelected(
                                e == null ?
                                [] :
                                e,
                              )
                            }
                          />
                        </Col>
                      </Form.Group> */}
                      <Form.Group as={Row} controlId="formHorizontalPrénom">
                        <Form.Label column sm={4}>
                          Plage horaire début:
                        </Form.Label>
                        <Col sm={8}>
                          <TimePickerComponent showHour={showHour ? showHour : null} setHour={setHour} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} controlId="formHorizontalPrénom">
                        <Form.Label column sm={4}>
                          plage horaire fin:
                        </Form.Label>
                        <Col sm={8}>
                          <TimePickerComponent showHour={showHour2 ? showHour2 : null} setHour={setHour2} />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row}>
                        <Button
                          className="btn-simple btn-round col-sm-6"
                          type="button"
                          variant="link"
                          onClick={() => setShowModal2(false)}
                        >
                          Fermer
                        </Button>
                        <Button className="btn-round col-sm-6" type="submit">
                          Modifier{' '}
                        </Button>{' '}
                      </Form.Group>
                    </Form>
                  </div>
                </Modal>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        className="modal modal-primary"
        show={showModal}
        onHide={() => setShowModal(false)}
        // onSubmit={(e) => createPerson(e)}
        autoFocus={false}
        enforceFocus={false}
      >
        <Modal.Header className="justify-content-center">
          <div className="modal-profile">
            <i className="nc-icon nc-simple-add"></i>
          </div>
        </Modal.Header>
        <div className="modal-body">
          <p className="text-center">Créér une nouvelle plage horaire ?</p>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Form.Group as={Row} controlId="formHorizontalCompany">
            <Form.Label column sm={4}>
              Batiment:
            </Form.Label>
            <Col sm={8}>
              <Select
                styles={customStyles}
                isClearable={true}
                isSearchable={true}
                components={animatedComponents}
                value={showBatimentSelected}
                options= {showBatiment}
                onChange={(e) =>
                  setBatimentSelected(
                    e == null ?
                    [] :
                    e,
                  )
                }
              />
            </Col>
          </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalPrénom">
              <Form.Label column sm={4}>
                Plage horaire début:
              </Form.Label>
              <Col sm={8}>
                <TimePickerComponent showHour={showHour ? showHour : null} setHour={setHour} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} controlId="formHorizontalPrénom">
              <Form.Label column sm={4}>
                plage horaire fin:
              </Form.Label>
              <Col sm={8}>
                <TimePickerComponent showHour={showHour2 ? showHour2 : null} setHour={setHour2} />
              </Col>
            </Form.Group>
            <Form.Group as={Row}>
              <Button
                className="btn-simple col-sm-6"
                type="button"
                variant="link"
                onClick={() => setShowModal(false)}
              >
                Fermer
              </Button>
              <Button className="btn-fill btn-round col-sm-6" type="submit">
                CRÉER{' '}
              </Button>{' '}
            </Form.Group>
          </Form>
        </div>
      </Modal>
    </Container>
  );
};

export default HourList;
